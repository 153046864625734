<template>
  <div :id="id"
       class="chart"></div>
</template>

<script>
export default {
  name: 'barChart',
  data () {
    return {
      id: 'echarts_' + 'barChart',
      echartObj: null,
      xData:[],
      yData:[]
    }
  },
  props:['barList'],
  watch: {
    barList: {
      handler (newValue, oldValue) {
        this.xData = []
        this.xData.push(newValue.currBalance)
        this.xData.push(newValue.prevCurrBalance)
        this.yData = []
        if(newValue.lastyearFlag){
          this.yData = [(new Date()).getFullYear(), (new Date()).getFullYear() - 1]
        }else{
          this.yData = [(new Date()).getMonth()+ 1, (new Date()).getMonth() == 0 ? '12' : (new Date()).getMonth()]
        }
        this.toDraw()
      },
      deep: true
    }
  },
  mounted () {
  },
  methods: {
    toDraw () {
      document.getElementById(this.id).style.display = "block";
      this.echartObj = this.$echarts.init(document.getElementById(this.id));
      let option = { 
        grid: {
          left: '3%',
          right: '8%',
          bottom: '10%',
          top: '18%',
          containLabel: true
        },
        series: [
          {
            type: 'bar',
            label: {
               normal: {
        show: true,
        position: 'top'
    }
            },
            data:[
                {value: this.xData[0],
                    itemStyle:{
                        color:'#3F7C53',
                    }
                },
                {value:this.xData[1],
                    itemStyle:{
                        color:'#F7D470',
                    }
                }
            ],
            barCategoryGap: 38,
            itemStyle:{
              barBorderRadius: 8,
            }
          }
        ],
        xAxis: {
          splitLine: {
            lineStyle: {
              color: '#A3A7B4',
              type: 'dotted'
            },
            show: true
          },
          axisTick: { show: false },
          axisLine: { show: false },
          boundaryGap: false,
          name: ''
        },
        yAxis: {
          type: 'category',
          data: this.yData,
          axisTick: { show: false },
          axisLine: { show: false },
          splitLine: {
            lineStyle: {
              color: '#A3A7B4',
              type: 'dotted'

            },
            show: true
          },
        }
      }
      this.echartObj.setOption(option);
    },
  },
}
</script>

<style lang="less" scoped>
.chart {
  height: 4rem;
  width: 100%;
}
</style>
