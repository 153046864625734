<template>
  <div :id="id"
       class="chart"></div>
</template>

<script>
export default {
  name: 'lineChart',
  data () {
    return {
      id: 'echarts_' + 'circleChart',
      echartObj: null,
    }
  },
  props:{
    newsiteChart: Number,
    signsiteChart: Number
  },
  watch: {
    newsiteChart: {
      handler (newValue, oldValue) {
       
        this.toDraw()
      },
      deep: true
    }
  },
  mounted () {
    this.toDraw()
  },
  methods: {
    toDraw () {
      document.getElementById(this.id).style.display = "block";
      this.echartObj = this.$echarts.init(document.getElementById(this.id));
      let option = {
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            startAngle:180,
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.newsiteChart,
                itemStyle: {
                  normal: {
                    color: '#B7DBBA',
                    barBorderRadius: 50
                  }
                },
                
              },
              {
                value: this.signsiteChart,
                itemStyle: {
                  normal: {
                    color: '#3F7C53',
                    barBorderRadius: 50
                  }
                }
              },
            ],
          }
        ]
      }
      this.echartObj.setOption(option);
    },
  },
}
</script>

<style lang="less" scoped>
.chart {
  height: 2.7rem;
  width: 50%;
}
</style>
