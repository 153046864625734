<template>
  <div class="ps-content">
    <div class="title"></div>
    <div class="new-card">
      <div class="flex top" @click="siteClick">
        <div class="top-left">{{barFlag ? '新上线站点': '已上线站点'}}</div>
        <div class="top-right flex">
          <div style="margin-right:0.1rem">
            <span>{{responseData.onlineCount}}</span>个   
          </div>  
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="mid"></div>
      <div class="bottom flex">
        <div class="bottom-item">
          <div>时点余额</div>
          <div class="money-count">{{responseData.incCurrBalance}}万元</div>
        </div>
        <div class="bottom-item">
          <div>客户数</div>
          <div>{{responseData.cardNum}}个</div>
        </div>
        <div>
        </div>
      </div>
      <div class="bottom flex">
        <div class="bottom-item">
          <div>节点时点余额</div>
          <div class="money-count">{{responseData.currBalance2}}万元</div>
        </div>
        <div class="bottom-item">
          <div>节点客户数</div>
          <div>{{responseData.cardNum2}}个</div>
        </div>
        <div>
        </div>
      </div>
    </div>
    <div class="site-count flex">
      <circleChart style="width: 2.7rem;" :newsiteChart="responseData.createCount" :signsiteChart="responseData.signCount"></circleChart>
      <div class="site-count__right">
        <div>站点数量统计</div>
        <div>新建站点 <span>{{responseData.createCount}}个</span></div>
        <div>签约站点 <span>{{responseData.signCount}}个</span></div>
      </div>
    </div>
    <div class="chart-wrap">
      <div class="chart-content flex" v-if="!barFlag">
        <div>时点余额</div>
        <div><span>{{responseData.currBalance}}</span> 万元</div>
      </div>
      <div class="chart-content flex" v-if="lastmonthFlag">
        <div>较上月新增</div>
        <div><span>{{responseData.incCurrBalance}}</span> 万元</div>
      </div>
      <div class="chart-content flex" v-if="lastyearFlag">
        <div>较上年末新增</div>
        <div><span>{{responseData.incCurrBalance}}</span> 万元</div>
      </div>
      <div class="last-content flex" v-if="barFlag">
        <div>时点余额</div>
        <div><span>{{responseData.currBalance}}</span> 万元</div>
      </div>
      <lineChart v-if="!barFlag" :lineList="lineData"></lineChart>
      <barChart v-if="barFlag" style="margin-top: -0.4rem" :barList="responseData"></barChart>

      <div class="icon-wrap">
        <img :src="timing" v-if="timingFlag">
        <img :src="cusNum" v-if="!lastmonthFlag && !lastyearFlag" style="width:1.26rem">
        <img :src="lastmonth" v-if="lastmonthFlag">
        <img :src="lastyear" v-if="lastyearFlag">
      </div>
    </div>
    <div class="time"> 更新时间： {{responseData.updateTime}}</div>

  </div>
</template>

<script>
import { getDeptPerformance } from '@/utils/api'
import lineChart from "./lineChart"
import barChart from "./barChart"
import circleChart from './circleChart'
export default {
  components: {
    lineChart, barChart, circleChart
  },
  props: {
    timingFlag: Boolean,
    lastmonthFlag: Boolean,
    lastyearFlag: Boolean,
    newsite: Number,
    signsite: Number,
    barFlag: Boolean,
    responseData: Object,
    lineData: Array,
    deptNo:String,
  },
  watch: {
    responseData: {
      handler (newValue, oldValue) {
        this.responseData = newValue
        this.responseData.lastyearFlag = this.lastyearFlag
      },
      deep: true
    }
  },
  data () {
    return {
      icon: require('@/assets/img/ps/icon.png'),
      card: require('@/assets/img/ps/card.png'),
      timing: require('@/assets/img/ps/timing.png'),
      lastmonth: require('@/assets/img/ps/lastmonth.png'),
      lastyear: require('@/assets/img/ps/lastyear.png'),
      cusNum: require('@/assets/img/ps/cusNum.png'),
    }
  },
  mounted () {
  },
  methods: {
    siteClick(){
      this.$router.push({name: 'siteList', query: {lastmonthFlag: this.lastmonthFlag, lastyearFlag: this.lastyearFlag, deptNo: this.deptNo}})
    }
  }
}
</script>

<style lang="less" scoped>
.ps-content {
  // height: 100%;
  // background: #f7f8f9;

  padding: 0 0.3rem;
  background: #f7f8f9;

  .title {
    font-size: 0.36rem;
    color: #272829;
    font-weight: bold;
    padding: 0.24rem 0;
  }
  .new-card {
    background: #ffffff;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.07);
    border-radius: 0.16rem;
    .top{
      position: relative;
      justify-content: space-between;
      padding: 0.4rem;
      .top-left{
        font-size: 0.3rem;
        color: #4A4A4A;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          background: #3F7C53;
          width: 0.08rem;
          height: 0.36rem;
          left: -0.4rem;
          top: 0.04rem;
        }
      }
      .top-right{
        font-size: 0.3rem;
        color: #999;
        align-items: center;
        span{
          color: #3F7C53;
        }
      }
    }
    .mid{
      width: 100%;
      height: 0.01rem;
      background: rgba(0,0,0,0.1);
    }
    .bottom{
      padding:.4rem;
      .bottom-item{
        flex: 1;
        .money-count{
          color: #3F7C53;
        }
        div:nth-child(1){
          color: #999;
          font-size: 0.24rem;
        }
        div:nth-child(2){
          font-size:0.3rem;
        }
      }
    }
    .new-card__left {
      flex-direction: column;
      align-items: flex-start;
      div {
        align-items: center;
      }
      span {
        font-weight: bold;
        margin-left: 0.25rem;
        font-size: 0.24rem;
      }
      p {
        font-size: 0.65rem;
        color: #3f7c53;
        font-weight: bold;
        padding-left: 0.45rem;
      }
    }
    .icon-img {
      width: 0.3rem;
    }
    .card-img {
      width: 1.3rem;
    }
  }
  .site-count {
    margin-top: 0.45rem;
    height: 2.72rem;
    background: #ffffff;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.07);
    border-radius: 0.16rem;
    align-items: center;
    .site-count__right {
      div {
        &:nth-child(1) {
          font-size: 0.36rem;
          color: #272829;
          margin-bottom: 0.2rem;
          letter-spacing: 0.04rem;
        }
        &:nth-child(2),
        &:nth-child(3) {
          font-size: 0.3rem;
          color: #96a7af;
          position: relative;
          padding-left: 0.5rem;
          letter-spacing: 0.04rem;
        }
        span {
          margin-left: 0.2rem;
        }
        &:nth-child(2),
        &:nth-child(3) {
          &:after {
            content: "";
            width: 0.32rem;
            height: 0.2rem;
            background: #b7dbba;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 0.1rem;
          }
        }
        &:nth-child(3) {
          &:after {
            background: #3f7c53;
          }
        }
      }
    }
  }
  .chart-wrap {
    margin-top: 0.45rem;
    height: 5rem;
    background: #ffffff;
    box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.07);
    border-radius: 0.32rem;
    position: relative;
    .chart-content {
      justify-content: space-between;
      padding: 0.3rem 0.3rem 0;
      div {
        &:nth-child(1) {
          font-size: 0.36rem;
          color: #141a22;
        }
        &:nth-child(2) {
          font-size: 0.26rem;
          color: #141a22;
          span {
            color: #3f7c53;
            font-size: 0.36rem;
            font-weight: bold;
          }
        }
      }
    }
    .last-content{
      justify-content: space-between;
      margin: 0.1rem 0.3rem 0 0.3rem;
      div{
        &:nth-child(1){
          font-size: 0.26rem;
          color: #999;
        }
        &:nth-child(2){
          font-size: 0.24rem;
          // color: #999;
          span{
            color: #141a22;
            font-weight: bold;
          }
        }
      }
    }
  }
  .icon-wrap {
    position: absolute;
    left: 0.5rem;
    bottom: -0.2rem;
    img {
      width: 1.5rem;
      margin-right: 0.16rem;
    }
  }
  .time {
    color: #9b9b9b;
    font-size: 0.23rem;
    margin-top: 0.4rem;
    padding-bottom: 1rem;
  }
}
</style>
