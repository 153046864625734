<template>
  <div class="ps-info">
    <threeLevelMenuOrg @oneChange="oneChange" @twoChange="twoChange" @threeChange="threeChange"></threeLevelMenuOrg>
    <van-tabs type="card"
              @click="tabClick" v-model="activeTab">
      <van-tab title="累计">
        <contentComponent :barFlag="false"
                          :timingFlag="true"
                          :lastmonthFlag="false"
                          :lastyearFlag="false"
                          :newsite="newsite"
                          :signsite="signsite"
                          :deptNo="tempDeptNo"
                          v-if="one"
                          :responseData="responseData"
                          :lineData="lineData"> </contentComponent>
      </van-tab>
      <van-tab title="本月">
        <contentComponent :barFlag="true"
                          :timingFlag="true"
                          :lastmonthFlag="true"
                          :lastyearFlag="false"
                          :newsite="newsite"
                          :signsite="signsite"
                          :deptNo="tempDeptNo"
                          v-if="two"
                          :responseData="responseData"> </contentComponent>
      </van-tab>
      <van-tab title="本年">
        <contentComponent :barFlag="true"
                          :timingFlag="true"
                          :lastmonthFlag="false"
                          :lastyearFlag="true"
                          :newsite="newsite"
                          :signsite="signsite"
                          :deptNo="tempDeptNo"
                          v-if="three"
                          :responseData="responseData"> </contentComponent>
      </van-tab>
    </van-tabs>

    <div class="nextBtn flex">
      <van-button class="publicBtnActive left-btn" @click="gotoPerformance">站点业绩分析</van-button>
      <van-button :class="!isShowButton?'publicBtnActive':'publicBtnActive2'" :disabled="isShowButton"
                  @click="goToHighGoals">业绩目标与达成</van-button>
    </div>

  </div>
</template>

<script>
import { getDeptPerformance, getPerPeriodPerformanceList,getMineInfo} from '@/utils/api'
import contentComponent from './contentComponent'
import { DropdownMenu, DropdownItem } from 'vant'
import common from '@/utils/common'
import threeLevelMenuOrg from '@/components/threeLevelMenuOrg'
import {uwStatisticAction} from "../../utils/util";
import {geUserDeptTree} from '@/utils/goals';
export default {
  components: {
    contentComponent,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    threeLevelMenuOrg
  },
  data () {
    return {
      newsite: 30,
      signsite: 10,
      one: true,
      two: false,
      three: false,
      responseData: {},
      lineData:[],
      tempDeptNo:'',
      activeTab: 0,
      userType:'',
      isCity:false,
      isShowButton:true
    }
  },
  created() {
    common.removeItem('proviceArr');
    common.removeItem('cityList');
    this.toGetMineType();
    this.getThreeData();
  },
  mounted () {
    this.togetDeptPerformance('','','')
    this.togetPerPeriodPerformanceList(common.time(new Date()), 7, '')
    uwStatisticAction('/ps/psInfo','业绩统计')
  },
  methods: {
    //获取3级联动数据
    getThreeData(){
      geUserDeptTree({token: common.getItem('wtToken')}).then(res=>{
        if(res.code === 200 && res.data.businessCode ===200){
          this.isShowButton = false;
          if(res.data.data.city && res.data.data.city.length > 0){
            this.isCity = true;
          }else {
            this.isCity = false;
          }
          let  proviceArr = res.data.data.provice?res.data.data.provice:[];
          if(proviceArr.length > 0){
            proviceArr = proviceArr.map(it=>{
              let objProvice = {};
              objProvice.text = it.deptName;
              objProvice.value = it.deptNo;
              return objProvice;
            });
            common.setItem('proviceArr', proviceArr);
          }
          let  cityList = res.data.data.city?res.data.data.city:[];
          if(cityList.length > 0){
            cityList = cityList.map(item=>{
              let objCity = {};
              objCity.text = item.deptName;
              objCity.value = item.deptNo;
              return objCity;
            })
            common.setItem('cityList', cityList);
          }


        }
      })
    },
    gotoPerformance(){
      this.$router.push({name: 'performanceAnalysis'})
    },
    togetDeptPerformance (startTime, endTime, deptNo) {
      getDeptPerformance({
        startTime,
        endTime,
        deptNo
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.responseData = res.data.data
          }
        }
      })
    },
    togetPerPeriodPerformanceList(startTime, perPeriodCount, deptNo){
      getPerPeriodPerformanceList({
        startTime,perPeriodCount,deptNo
      }).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
           this.lineData = res.data.data
          }
        }
      })
    },
    tabClick (index) {
      this.one = false
      this.two = false
      this.three = false

      if (index == 0) {
        this.newsite = 30
        this.signsite = 10
        this.one = true
        this.togetDeptPerformance('', '', this.tempDeptNo)
        this.togetPerPeriodPerformanceList(common.time(new Date()), 7, this.tempDeptNo)
      }
      if (index == 1) {
        this.newsite = 300
        this.signsite = 10
        this.two = true
        this.togetDeptPerformance(common.timeMonth(new Date()), common.timeHMS(new Date()), this.tempDeptNo)
      }
      if (index == 2) {
        this.newsite = 30
        this.signsite = 100
        this.three = true
        this.togetDeptPerformance((new Date()).getFullYear() + '-1-1', common.timeHMS(new Date()), this.tempDeptNo)
      }
    },
    oneChange(val){
      let startTime
      let endTime
      if(this.activeTab == 0){
        startTime = ''
        endTime = ''
      }
      if(this.activeTab == 1){
        startTime = common.timeMonth(new Date())
        endTime = common.timeHMS(new Date())
      }
      if(this.activeTab == 2){
        startTime = (new Date()).getFullYear() + '-1-1'
        endTime = common.timeHMS(new Date())
      }

      this.togetDeptPerformance(startTime, endTime, val)
      if( this.activeTab == 0){
        this.togetPerPeriodPerformanceList(common.time(new Date()), 7, val)
      }
      this.tempDeptNo = val
    },
    twoChange(val){
      let startTime
      let endTime
      if(this.activeTab == 0){
        startTime = ''
        endTime = ''
      }
      if(this.activeTab == 1){
        startTime = common.timeMonth(new Date())
        endTime = common.timeHMS(new Date())
      }
      if(this.activeTab == 2){
        startTime = (new Date()).getFullYear() + '-1-1'
        endTime = common.timeHMS(new Date())
      }
      this.togetDeptPerformance(startTime, endTime, val)
      if(this.activeTab == 0){
        this.togetPerPeriodPerformanceList(common.time(new Date()), 7, val)
      }
      this.tempDeptNo = val
    },
    threeChange(val){
      let startTime
      let endTime
      if(this.activeTab == 0){
        startTime = ''
        endTime = ''
      }
      if(this.activeTab == 1){
        startTime = common.timeMonth(new Date())
        endTime = common.timeHMS(new Date())
      }
      if(this.activeTab == 2){
        startTime = (new Date()).getFullYear() + '-1-1'
        endTime = common.timeHMS(new Date())
      }
      this.togetDeptPerformance(startTime, endTime, val)
      if(this.activeTab == 0){
        this.togetPerPeriodPerformanceList(common.time(new Date()), 7, val)
      }
      this.tempDeptNo = val
    },
    //获取用户类型
    toGetMineType(){
      getMineInfo({
        'uniqueNo':common.getItem('uniqueNo'),
      }).then(res=>{
        if(res.code===200 && res.data.businessCode ===200){
          this.userType = res.data.userType;
        }
      })
    },
    //跳转到 目标与达成页面
    goToHighGoals(){
      if(this.userType === '0'){ //运营人员
        this.$router.push({name:'operationGoals', query:{uniqueNo: this.$route.query.uniqueNo}});
      }else{//高级用户及负责人(分有市没市)
        if(this.isCity){
          this.$router.push('/ps/cityLevelGoals');
        }else{
          this.$router.push('/ps/highGoals');
        }
      }
    }

  }
}
</script>

<style lang="less">
.ps-info {
  .van-dropdown-item{
    z-index: 11;
  }
  .van-tabs--card > .van-tabs__wrap {
    height: 1.2rem !important;
    padding: 0.24rem 0 0.24rem;
    background: #fff;
    // position: fixed;
    width: 100%;
    margin: 0 !important;
  }
  .van-tabs__nav--card {
    border: none;
    height: 0.7rem;
    background: #f5f5f5;
    border-radius: 0.5rem;
    border-right: none;
  }
  .van-tabs__nav--card .van-tab {
    color: #333333;
    border-right: none;
  }
  .van-tabs__nav--card .van-tab.van-tab--active {
    background: #3f7c53;
    border-radius: 0.5rem;
    color: #fff;
  }
  .van-tabs--card > .van-tabs__wrap {
    height: 1rem;
    margin: 0.24rem 0 0;
    background: #fff;
  }
  .van-tabs__content{
    // padding-top: 1.2rem;
  }
  .van-dropdown-menu{
    padding: 0 40px;
  }
  .van-dropdown-menu__item{

    display: flex;
    width: 32%;
    height: 100%;
    justify-content: flex-start;

  }
  .van-dropdown-menu__bar{
    height: 0.76rem;
    line-height: 0.76rem;
  }
  .van-ellipsis{
    // color: #3f7c53;
    font-size: 0.26rem;
  }
  .van-cell{
    padding: 0 0.32rem;
    height: 0.76rem;
    line-height: 0.76rem;
  }
  .flex {
    display: flex;
  }

  .nextBtn {
    width: 100%;
    padding: 12px;
    background: #f7f8f9;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index:9999;
    .left-btn{
      background: #fff;
      color:#333;
      border: 1px solid #E5E5E5;
      margin-right: 0.24rem;
    }
  }
}
</style>

<style lang="less">
.ps-info {
  height: 100%;
  // background: #f7f8f9;
  .van-dropdown-menu{
    padding: 0 !important;
  }
  .van-dropdown-menu__item{
    justify-content: center;
  }
  .publicBtnActive2{
    height:49px;
    line-height: 49px;
    text-align: center;
    background:#C7C7C7;
    border-radius:4px;
    font-size:18px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:#ffffff;
    width: 100%;
    opacity:1;
  }
}
</style>
